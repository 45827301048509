import { Container, Flex, Heading, List, ListItem, Text } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { GalleryCard } from '../components/GalleryCard';
import { Layout } from '../components/Layout';
import { QuestionCard } from '../components/QuestionCard';
import '../global.css';
import { GalleriesQueryResult } from '../types';

interface Props {
    data: GalleriesQueryResult;
}

const Page: FC<Props> = ({ data }) => {
    return (
        <Layout title="Balkoniki francuskie">
            <Container alignSelf="center" maxW={['container.sm', 'container.xl']} px={['4', '10']} mt={['4', '12']}>
                <Flex gap="10" direction="column">
                    <Heading
                        scrollMarginTop="4"
                        mx={['12', '0']}
                        as="h2"
                        size="2xl"
                        variant="secondary"
                        alignSelf="flex-start"
                    >
                        Balustrady na balkoniki francuskie
                    </Heading>

                    <GalleryCard
                        fileEdges={data.fran.edges}
                        id="realizacje-balkoniki"
                        title="Balustrady na balkoniki francuskie"
                    />

                    <QuestionCard defaultExpanded title="Dlaczego wybrać nasze balkoniki francuskie?">
                        <Heading as="h4" size="md">
                            Estetyka
                        </Heading>
                        <Text mt={['2']}>
                            To eleganckie i stylowe rozwiązanie, które doda uroku Twojemu domowi lub mieszkaniu.
                        </Text>

                        <Heading as="h4" size="md" mt={['6']}>
                            Funkcjonalność
                        </Heading>
                        <Text mt={['2']}>
                            Balkoniki francuskie to funkcjonalny sposób zabezpieczenia drzwi balkonowych.
                        </Text>

                        <Heading as="h4" size="md" mt={['6']}>
                            Bezpieczeństwo
                        </Heading>
                        <Text mt={['2']}>
                            Nasze balkoniki są wykonane z najwyższej jakości materiałów i spełniają wymagania
                            bezpieczeństwa, zapewniając ochronę Twojej rodziny i gości.
                        </Text>

                        <Heading as="h4" size="md" mt={['6']}>
                            Montaż
                        </Heading>
                        <Text mt={['2']}>
                            Profesjonalny montaż oferowany przez naszą firmę gwarantuje zgodność z normami
                            bezpieczeństwa.
                        </Text>
                    </QuestionCard>
                </Flex>
            </Container>
        </Layout>
    );
};

export default Page;

/*
TODO:
* improve SEO (roobts.txt)
* play with the images sizes & formats (only jpg for full?)
* improve desktop gallery

*/
export const query = graphql`
    fragment MyGallery on FileConnection {
        edges {
            node {
                id
                name
                thumb: childImageSharp {
                    gatsbyImageData(height: 300, placeholder: BLURRED, layout: CONSTRAINED)
                }
                full: childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
                }
            }
        }
    }

    query FranQuery {
        fran: allFile(filter: { relativeDirectory: { eq: "galleries/fran" } }) {
            ...MyGallery
        }
    }
`;
